import React from 'react';
import {
  Grid, Hidden, Typography, makeStyles,
} from '@material-ui/core';
import HeroBackground from './HeroBackground';
import StyledButton from '../atoms/StyledButton';
import EmployeePictures from '../atoms/EmployeePictures';
import Spacer from '../atoms/Spacer';
import ShowInMobile from '../atoms/ShowInMobile';
import CampanhaImageDesktop from '../../../static/images/061DesktopBannerSiteConubeMedicos.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .span': {
      color: `${theme.palette.info.main} !important`,
    },

    '& .title strong': {
      color: theme.palette.info.highlight,
    },
  },
  description: {
    maxWidth: '390px',
  },
  button: {
    textDecoration: 'none',
    maxWidth: '65%',

    '@media screen and (max-width: 959px)': {
      maxWidth: '85%',
    },
  },
  photos: {
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  photoImg: {
    width: '100%',
  },
}));

const HomeHero = () => {
  const classes = useStyles();

  return (
    <HeroBackground page="home">
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={6} xs={12} className="text">
          <Typography className="span">Nascemos com um propósito</Typography>

          <Typography variant="h3" component="h1" className="title">
            Ser uma
            {' '}
            <strong>contabilidade</strong>
            <br />
            <strong> digital feita sob medida</strong>
            {' '}
            para
            <br />
            o seu negócio
          </Typography>

          <Typography
            variant="body1"
            component="h2"
            className={classes.description}
          >
            Abra a sua empresa, emita notas fiscais em 1 minuto e
            economize até R$ 5 mil por ano.
          </Typography>

          <Spacer size={20} />

          <a href="/quanto-custa/" className={classes.button}>
            <StyledButton
              text="Saiba quanto custa"
              variant="contained"
              color="secondary"
              width="100%"
            />
          </a>
        </Grid>

        <ShowInMobile breakpoint={959}>
          <Grid item md={6} xs={12} className={classes.photos} style={{ marginTop: '50px' }}>
            <a href="https://seja.conube.com.br/contabilidade-medicos-pj" target="_blank" rel="noreferrer">
              <img className={classes.photoImg} alt="campanha" src={CampanhaImageDesktop} />
            </a>

            {/* <EmployeePictures /> */}
          </Grid>
        </ShowInMobile>
      </Grid>
    </HeroBackground>
  );
};

export default HomeHero;
